import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { lastValueFrom } from 'rxjs';
declare var google: any;

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  apiUrl = 'https://api.ipdata.co/?api-key=9f04080b0a439f94ba6663898e09f69aba65cc0aee811f1c0089eb7a'


  constructor(private _http: HttpService) {

  }

  getCurrentCountryCode() {
    return lastValueFrom(this._http.get(this.apiUrl))
  }


  getCurrentLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve(position),
          (error) => reject(error)
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  }

  getCountryCode(lat: number, lng: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(lat, lng);

      geocoder.geocode({ location: latlng }, (results:any, status:any) => {
        if (status === google.maps.GeocoderStatus.OK && results[0]) {
          const components = results[0].address_components;
          const countryComponent = components.find((comp:any) => comp.types.includes('country'));
          resolve(countryComponent ? countryComponent.short_name : 'Unknown');
        } else {
          reject('Geocoder failed due to: ' + status);
        }
      });
    });
  }
}