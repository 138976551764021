export const COUNTRY_PHONE_CODES = [
  {
    countryCode: 'AF',
    countryName: 'Afghanistan',
    dialingCode: '+93'
  },
  {
    countryCode: 'AL',
    countryName: 'Albania',
    dialingCode: '+355',
  },
  {
    countryCode: 'DZ',
    countryName: 'Algeria',
    dialingCode: '+213'
  },
  {
    countryCode: 'AS',
    countryName: 'American Samoa',
    dialingCode: '+1'
  },
  {
    countryCode: 'AD',
    countryName: 'Andorra',
    dialingCode: '+376'
  },
  {
    countryCode: 'AO',
    countryName: 'Angola',
    dialingCode: '+244'
  },
  {
    countryCode: 'AI',
    countryName: 'Anguilla',
    dialingCode: '+1'
  },
  {
    countryCode: 'AG',
    countryName: 'AB',
    dialingCode: '+1'
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    dialingCode: '+54'
  },
  {
    countryCode: 'AM',
    countryName: 'Armenia',
    dialingCode: '+374'
  },
  {
    countryCode: 'AW',
    countryName: 'Aruba',
    dialingCode: '+297'
  },
  {
    countryCode: 'AC',
    countryName: 'Ascension',
    dialingCode: '+247'
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    dialingCode: '+61',
  },
  {
    countryCode: 'AX',
    countryName: 'AET',
    dialingCode: '+672',
  },
  {
    countryCode: 'AT',
    countryName: 'Austria',
    dialingCode: '+43'
  },
  {
    countryCode: 'AZ',
    countryName: 'Azerbaijan',
    dialingCode: '+994'
  },
  {
    countryCode: 'BS',
    countryName: 'Bahamas',
    dialingCode: '+1'
  },
  {
    countryCode: 'BH',
    countryName: 'Bahrain',
    dialingCode: '+973'
  },
  {
    countryCode: 'BD',
    countryName: 'Bangladesh',
    dialingCode: '+880'
  },
  {
    countryCode: 'BB',
    countryName: 'Barbados',
    dialingCode: '+1'
  },
  {
    countryCode: 'BY',
    countryName: 'Belarus',
    dialingCode: '+375'
  },
  {
    countryCode: 'BE',
    countryName: 'Belgium',
    dialingCode: '+32'
  },
  {
    countryCode: 'BZ',
    countryName: 'Belize',
    dialingCode: '+501'
  },
  {
    countryCode: 'BJ',
    countryName: 'Benin',
    dialingCode: '+229'
  },
  {
    countryCode: 'BM',
    countryName: 'Bermuda',
    dialingCode: '+1'
  },
  {
    countryCode: 'BT',
    countryName: 'Bhutan',
    dialingCode: '+975'
  },
  {
    countryCode: 'BO',
    countryName: 'Bolivia',
    dialingCode: '+591'
  },
  {
    countryCode: 'BA',
    countryName: 'BH',
    dialingCode: '+387'
  },
  {
    countryCode: 'BW',
    countryName: 'Botswana',
    dialingCode: '+267'
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    dialingCode: '+55'
  },
  {
    countryCode: 'VG',
    countryName: 'BVI',
    dialingCode: '+1'
  },
  {
    countryCode: 'BN',
    countryName: 'Brunei Darussalam',
    dialingCode: '+673'
  },
  {
    countryCode: 'BG',
    countryName: 'Bulgaria',
    dialingCode: '+359'
  },
  {
    countryCode: 'BF',
    countryName: 'Burkina Faso',
    dialingCode: '+226'
  },
  {
    countryCode: 'BI',
    countryName: 'Burundi',
    dialingCode: '+257'
  },
  {
    countryCode: 'KH',
    countryName: 'Cambodia',
    dialingCode: '+855'
  },
  {
    countryCode: 'CM',
    countryName: 'Cameroon',
    dialingCode: '+237'  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    dialingCode: '+1'  },
  {
    countryCode: 'CV',
    countryName: 'Cape Verde',
    dialingCode: '+238'  },
  {
    countryCode: 'KY',
    countryName: 'Cayman Islands',
    dialingCode: '+1'  },
  {
    countryCode: 'CF',
    countryName: 'CAR',
    dialingCode: '+236'  },
  {
    countryCode: 'TD',
    countryName: 'Chad',
    dialingCode: '+235'  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
    dialingCode: '+56'  },
  {
    countryCode: 'CN',
    countryName: 'China',
    dialingCode: '+86'  },
  {
    countryCode: 'CX',
    countryName: 'Christmas Island',
    dialingCode: '+61'  },
  {
    countryCode: 'CO',
    countryName: 'Colombia',
    dialingCode: '+57'  },
  {
    countryCode: 'KM',
    countryName: 'Comoros',
    dialingCode: '+269'  },
  {
    countryCode: 'CG',
    countryName: 'Congo',
    dialingCode: '+242'  },
  {
    countryCode: 'CK',
    countryName: 'Cook Islands',
    dialingCode: '+682'  },
  {
    countryCode: 'CR',
    countryName: 'Costa Rica',
    dialingCode: '+506'  },
  {
    countryCode: 'CI',
    countryName: "Cote d'Ivoire",
    dialingCode: '+225'  },
  {
    countryCode: 'HR',
    countryName: 'Croatia',
    dialingCode: '+385'  },
  {
    countryCode: 'CU',
    countryName: 'Cuba',
    dialingCode: '+53'  },
  {
    countryCode: 'CY',
    countryName: 'Cyprus',
    dialingCode: '+357'  },
  {
    countryCode: 'CZ',
    countryName: 'Czech Republic',
    dialingCode: '+420'  },
  {
    countryCode: 'CD',
    countryName: 'DRC',
    dialingCode: '+243'  },
  {
    countryCode: 'DK',
    countryName: 'Denmark',
    dialingCode: '+45'  },
  {
    countryCode: 'DG',
    countryName: 'Diego Garcia',
    dialingCode: '+246'  },
  {
    countryCode: 'DJ',
    countryName: 'Djibouti',
    dialingCode: '+253'  },
  {
    countryCode: 'DM',
    countryName: 'Dominica',
    dialingCode: '+1'  },
  {
    countryCode: 'DO',
    countryName: 'Dominican Republic',
    dialingCode: '+1'  },
  {
    countryCode: 'TL',
    countryName: 'East Timor',
    dialingCode: '+670'  },
  {
    countryCode: 'EC',
    countryName: 'Ecuador',
    dialingCode: '+593'  },
  {
    countryCode: 'EG',
    countryName: 'Egypt',
    dialingCode: '+20'  },
  {
    countryCode: 'SV',
    countryName: 'El Salvador',
    dialingCode: '+503'  },
  {
    countryCode: 'GQ',
    countryName: 'Equatorial Guinea',
    dialingCode: '+240'  },
  {
    countryCode: 'ER',
    countryName: 'Eritrea',
    dialingCode: '+291'  },
  {
    countryCode: 'EE',
    countryName: 'Estonia',
    dialingCode: '+372'  },
  {
    countryCode: 'ET',
    countryName: 'Ethiopia',
    dialingCode: '+251'  },
  {
    countryCode: 'FK',
    countryName: 'Falkland Islands',
    dialingCode: '+500'  },
  {
    countryCode: 'FO',
    countryName: 'Faroe Islands',
    dialingCode: '+298'  },
  {
    countryCode: 'FJ',
    countryName: 'Fiji',
    dialingCode: '+679'  },
  {
    countryCode: 'FI',
    countryName: 'Finland',
    dialingCode: '+358'  },
  {
    countryCode: 'FR',
    countryName: 'France',
    dialingCode: '+33'  },
  {
    countryCode: 'GF',
    countryName: 'French Guiana',
    dialingCode: '+594'  },
  {
    countryCode: 'PF',
    countryName: 'French Polynesia',
    dialingCode: '+689'  },
  {
    countryCode: 'GA',
    countryName: 'Gabon',
    dialingCode: '+241'  },
  {
    countryCode: 'GM',
    countryName: 'Gambia',
    dialingCode: '+220'  },
  {
    countryCode: 'GE',
    countryName: 'Georgia',
    dialingCode: '+995'  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    dialingCode: '+49'  },
  {
    countryCode: 'GH',
    countryName: 'Ghana',
    dialingCode: '+233'  },
  {
    countryCode: 'GI',
    countryName: 'Gibraltar',
    dialingCode: '+350'  },
  {
    countryCode: 'GR',
    countryName: 'Greece',
    dialingCode: '+30'  },
  {
    countryCode: 'GL',
    countryName: 'Greenland',
    dialingCode: '+299'  },
  {
    countryCode: 'GD',
    countryName: 'Grenada',
    dialingCode: '+1'  },
  {
    countryCode: 'GP',
    countryName: 'Guadeloupe',
    dialingCode: '+590'  },
  {
    countryCode: 'GU',
    countryName: 'Guam',
    dialingCode: '+1'  },
  {
    countryCode: 'GT',
    countryName: 'Guatemala',
    dialingCode: '+502'  },
  {
    countryCode: 'GN',
    countryName: 'Guinea',
    dialingCode: '+224'  },
  {
    countryCode: 'GW',
    countryName: 'Guinea-Bissau',
    dialingCode: '+245'  },
  {
    countryCode: 'GY',
    countryName: 'Guyana',
    dialingCode: '+592'  },
  {
    countryCode: 'HT',
    countryName: 'Haiti',
    dialingCode: '+509'  },
  {
    countryCode: 'HN',
    countryName: 'Honduras',
    dialingCode: '+504'  },
  {
    countryCode: 'HK',
    countryName: 'Hong Kong',
    dialingCode: '+852'  },
  {
    countryCode: 'HU',
    countryName: 'Hungary',
    dialingCode: '+36'  },
  {
    countryCode: 'IS',
    countryName: 'Iceland',
    dialingCode: '+354'  },
  {
    countryCode: 'IN',
    countryName: 'India',
    dialingCode: '+91'  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    dialingCode: '+62'  },
  {
    countryCode: 'IR',
    countryName: 'Iran',
    dialingCode: '+98'  },
  {
    countryCode: 'IQ',
    countryName: 'Iraq',
    dialingCode: '+964'
  },
  {
    countryCode: 'IE',
    countryName: 'Ireland',
    dialingCode: '+353'
  },
  {
    countryCode: 'IL',
    countryName: 'Israel',
    dialingCode: '+972'
  },
  {
    countryCode: 'IT',
    countryName: 'Italy',
    dialingCode: '+39'
  },
  {
    countryCode: 'JM',
    countryName: 'Jamaica',
    dialingCode: '+1'
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    dialingCode: '+81'
  },
  {
    countryCode: 'JE',
    countryName: 'Jersey',
    dialingCode: '+44'
  },
  {
    countryCode: 'JO',
    countryName: 'Jordan',
    dialingCode: '+962'
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    dialingCode: '+7'
  },
  {
    countryCode: 'KE',
    countryName: 'Kenya',
    dialingCode: '+254'
  },
  {
    countryCode: 'KI',
    countryName: 'Kiribati',
    dialingCode: '+686'
  },
  {
    countryCode: 'XK',
    countryName: 'Kosovo',
    dialingCode: '+383'
  },
  {
    countryCode: 'KW',
    countryName: 'Kuwait',
    dialingCode: '+965'
  },
  {
    countryCode: 'KG',
    countryName: 'Kyrgyzstan',
    dialingCode: '+996'
  },
  {
    countryCode: 'LA',
    countryName: 'Laos',
    dialingCode: '+856'
  },
  {
    countryCode: 'LV',
    countryName: 'Latvia',
    dialingCode: '+371'
  },
  {
    countryCode: 'LB',
    countryName: 'Lebanon',
    dialingCode: '+961'
  },
  {
    countryCode: 'LS',
    countryName: 'Lesotho',
    dialingCode: '+266'
  },
  {
    countryCode: 'LR',
    countryName: 'Liberia',
    dialingCode: '+231'
  },
  {
    countryCode: 'LY',
    countryName: 'Libya',
    dialingCode: '+218'
  },
  {
    countryCode: 'LI',
    countryName: 'Liechtenstein',
    dialingCode: '+423'
  },
  {
    countryCode: 'LT',
    countryName: 'Lithuania',
    dialingCode: '+370'
  },
  {
    countryCode: 'LU',
    countryName: 'Luxembourg',
    dialingCode: '+352'
  },
  {
    countryCode: 'MO',
    countryName: 'Macao',
    dialingCode: '+853'
  },
  {
    countryCode: 'MK',
    countryName: 'Macedonia',
    dialingCode: '+389'
  },
  {
    countryCode: 'MG',
    countryName: 'Madagascar',
    dialingCode: '+261'
  },
  {
    countryCode: 'MW',
    countryName: 'Malawi',
    dialingCode: '+265'
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    dialingCode: '+60'
  },
  {
    countryCode: 'MV',
    countryName: 'Maldives',
    dialingCode: '+960'
  },
  {
    countryCode: 'ML',
    countryName: 'Mali',
    dialingCode: '+223'
  },
  {
    countryCode: 'MT',
    countryName: 'Malta',
    dialingCode: '+356'
  },
  {
    countryCode: 'MH',
    countryName: 'Marshall Islands',
    dialingCode: '+692'
  },
  {
    countryCode: 'MQ',
    countryName: 'Martinique',
    dialingCode: '+596'
  },
  {
    countryCode: 'MR',
    countryName: 'Mauritania',
    dialingCode: '+222'
  },
  {
    countryCode: 'MU',
    countryName: 'Mauritius',
    dialingCode: '+230'
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    dialingCode: '+52'
  },
  {
    countryCode: 'FM',
    countryName: 'Micronesia',
    dialingCode: '+691'
  },
  {
    countryCode: 'MD',
    countryName: 'Moldova',
    dialingCode: '+373'
  },
  {
    countryCode: 'MC',
    countryName: 'Monaco',
    dialingCode: '+377'
  },
  {
    countryCode: 'MN',
    countryName: 'Mongolia',
    dialingCode: '+976'
  },
  {
    countryCode: 'ME',
    countryName: 'Montenegro',
    dialingCode: '+382'
  },
  {
    countryCode: 'MS',
    countryName: 'Montserrat',
    dialingCode: '+1'
  },
  {
    countryCode: 'MA',
    countryName: 'Moroccto',
    dialingCode: '+212'
  },
  {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    dialingCode: '+258'
  },
  {
    countryCode: 'MM',
    countryName: 'Myanmar',
    dialingCode: '+95'
  },
  {
    countryCode: 'NA',
    countryName: 'Namibia',
    dialingCode: '+264'
  },
  {
    countryCode: 'NR',
    countryName: 'Nauru',
    dialingCode: '+674'
  },
  {
    countryCode: 'NP',
    countryName: 'Nepal',
    dialingCode: '+977'
  },
  {
    countryCode: 'NL',
    countryName: 'Netherlands',
    dialingCode: '+31'
  },
  {
    countryCode: 'AN',
    countryName: 'Netherlands Antilles',
    dialingCode: '+599'
  },
  {
    countryCode: 'NC',
    countryName: 'New Caledonia',
    dialingCode: '+687'
  },
  {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    dialingCode: '+64'
  },
  {
    countryCode: 'NI',
    countryName: 'Nicaragua',
    dialingCode: '+505'
  },
  {
    countryCode: 'NE',
    countryName: 'Niger',
    dialingCode: '+227'
  },
  {
    countryCode: 'NG',
    countryName: 'Nigeria',
    dialingCode: '+234'
  },
  {
    countryCode: 'NU',
    countryName: 'Niue',
    dialingCode: '+683'
  },
  {
    countryCode: 'KP',
    countryName: 'North Korea',
    dialingCode: '+850'
  },
  {
    countryCode: 'MP',
    countryName: 'NMI',
    dialingCode: '+1'
  },
  {
    countryCode: 'NO',
    countryName: 'Norway',
    dialingCode: '+47'
  },
  {
    countryCode: 'OM',
    countryName: 'Oman',
    dialingCode: '+968'
  },
  {
    countryCode: 'PK',
    countryName: 'Pakistan',
    dialingCode: '+92'
  },
  {
    countryCode: 'PW',
    countryName: 'Palau',
    dialingCode: '+680'
  },
  {
    countryCode: 'PS',
    countryName: 'Palestine',
    dialingCode: '+970'
  },
  {
    countryCode: 'PA',
    countryName: 'Panama',
    dialingCode: '+507'
  },
  {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    dialingCode: '+675'
  },
  {
    countryCode: 'PY',
    countryName: 'Paraguay',
    dialingCode: '+595'
  },
  {
    countryCode: 'PE',
    countryName: 'Peru',
    dialingCode: '+51'
  },
  {
    countryCode: 'PH',
    countryName: 'Philippines',
    dialingCode: '+63'
  },
  {
    countryCode: 'PL',
    countryName: 'Poland',
    dialingCode: '+48'
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    dialingCode: '+351'
  },
  {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    dialingCode: '+1'
  },
  {
    countryCode: 'QA',
    countryName: 'Qatar',
    dialingCode: '+974'
  },
  {
    countryCode: 'RE',
    countryName: 'Reunion',
    dialingCode: '+262'
  },
  {
    countryCode: 'RO',
    countryName: 'Romania',
    dialingCode: '+40'
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    dialingCode: '+7'
  },
  {
    countryCode: 'RW',
    countryName: 'Rwanda',
    dialingCode: '+250'
  },
  {
    countryCode: 'SH',
    countryName: 'Saint Helena',
    dialingCode: '+290'
  },
  {
    countryCode: 'KN',
    countryName: 'Saint Kitts and Nevis',
    dialingCode: '+1'
  },
  {
    countryCode: 'LC',
    countryName: 'Saint Lucia',
    dialingCode: '+1'
  },
  {
    countryCode: 'PM',
    countryName: 'SPM',
    dialingCode: '+508'
  },
  {
    countryCode: 'VC',
    countryName: 'SVG',
    dialingCode: '+1'
  },
  {
    countryCode: 'WS',
    countryName: 'Samoa',
    dialingCode: '+685'
  },
  {
    countryCode: 'SM',
    countryName: 'San Marino',
    dialingCode: '+378'
  },
  {
    countryCode: 'ST',
    countryName: 'STP',
    dialingCode: '+239'
  },
  {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    dialingCode: '+966'
  },
  {
    countryCode: 'SN',
    countryName: 'Senegal',
    dialingCode: '+221'
  },
  {
    countryCode: 'RS',
    countryName: 'Serbia',
    dialingCode: '+381'
  },
  {
    countryCode: 'SC',
    countryName: 'Seychelles',
    dialingCode: '+248'
  },
  {
    countryCode: 'SL',
    countryName: 'Sierra Leone',
    dialingCode: '+232'
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    dialingCode: '+65'
  },
  {
    countryCode: 'SK',
    countryName: 'Slovakia',
    dialingCode: '+421'
  },
  {
    countryCode: 'SI',
    countryName: 'Slovenia',
    dialingCode: '+386'
  },
  {
    countryCode: 'SB',
    countryName: 'Solomon Islands',
    dialingCode: '+677'
  },
  {
    countryCode: 'SO',
    countryName: 'Somalia',
    dialingCode: '+252'
  },
  {
    countryCode: 'ZA',
    countryName: 'South Africa',
    dialingCode: '+27'
  },
  {
    countryCode: 'KR',
    countryName: 'South Korea',
    dialingCode: '+82'
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    dialingCode: '+34'
  },
  {
    countryCode: 'LK',
    countryName: 'Sri Lanka',
    dialingCode: '+94'
  },
  {
    countryCode: 'SD',
    countryName: 'Sudan',
    dialingCode: '+249'
  },
  {
    countryCode: 'SR',
    countryName: 'Suriname',
    dialingCode: '+597'
  },
  {
    countryCode: 'SZ',
    countryName: 'Swaziland',
    dialingCode: '+268'
  },
  {
    countryCode: 'SE',
    countryName: 'Sweden',
    dialingCode: '+46'
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
    dialingCode: '+41'
  },
  {
    countryCode: 'SY',
    countryName: 'Syria',
    dialingCode: '+963'
  },
  {
    countryCode: 'TW',
    countryName: 'Taiwan',
    dialingCode: '+886'
  },
  {
    countryCode: 'TJ',
    countryName: 'Tajikistan',
    dialingCode: '+992'
  },
  {
    countryCode: 'TZ',
    countryName: 'Tanzania',
    dialingCode: '+255'
  },
  {
    countryCode: 'TH',
    countryName: 'Thailand',
    dialingCode: '+66'
  },
  {
    countryCode: 'TG',
    countryName: 'Togo',
    dialingCode: '+228'
  },
  {
    countryCode: 'TK',
    countryName: 'Tokelau',
    dialingCode: '+690'
  },
  {
    countryCode: 'TO',
    countryName: 'Tonga',
    dialingCode: '+676'
  },
  {
    countryCode: 'TT',
    countryName: 'Trinidad and Tobago',
    dialingCode: '+1'
  },
  {
    countryCode: 'TN',
    countryName: 'Tunisia',
    dialingCode: '+216'
  },
  {
    countryCode: 'TR',
    countryName: 'Turkey',
    dialingCode: '+90'
  },
  {
    countryCode: 'TM',
    countryName: 'Turkmenistan',
    dialingCode: '+993'
  },
  {
    countryCode: 'TC',
    countryName: 'TCI',
    dialingCode: '+1'
  },
  {
    countryCode: 'TV',
    countryName: 'Tuvalu',
    dialingCode: '+688'
  },
  {
    countryCode: 'UG',
    countryName: 'Uganda',
    dialingCode: '+256'
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    dialingCode: '+380'
  },
  {
    countryCode: 'AE',
    countryName: 'UAE',
    dialingCode: '+971'
  },
  {
    countryCode: 'GB',
    countryName: 'United Kingdom',
    dialingCode: '+44'
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    dialingCode: '+1'
  },
  {
    countryCode: 'UY',
    countryName: 'Uruguay',
    dialingCode: '+598'
  },
  {
    countryCode: 'VI',
    countryName: 'US Virgin Islands',
    dialingCode: '+1'
  },
  {
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
    dialingCode: '+998'
  },
  {
    countryCode: 'VU',
    countryName: 'Vanuatu',
    dialingCode: '+678'
  },
  {
    countryCode: 'VA',
    countryName: 'Vatican City',
    dialingCode: '+379'
  },
  {
    countryCode: 'VE',
    countryName: 'Venezuela',
    dialingCode: '+58'
  },
  {
    countryCode: 'VN',
    countryName: 'Vietnam',
    dialingCode: '+84'
  },
  {
    countryCode: 'WF',
    countryName: 'Wallis and Futuna',
    dialingCode: '+681'
  },
  {
    countryCode: 'YE',
    countryName: 'Yemen',
    dialingCode: '+967'
  },
  {
    countryCode: 'ZM',
    countryName: 'Zambia',
    dialingCode: '+260'
  },
  {
    countryCode: 'ZW',
    countryName: 'Zimbabwe',
    dialingCode: '+263'
  }
];
