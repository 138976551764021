import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { RegistrationFieldSections } from 'src/app/layout/constants/interface/interface';
import { RegistrationAction } from './registration.actions';
import { RegistrationService } from 'src/app/layout/pages/registration/services/registration.service';
import { PopupService } from 'src/app/services/popup';
import { FILE_DOWNLOAD_SUCCESSFULLY, FILE_UPLOAD_SUCCESSFULLY } from 'src/app/constants/messages.constants';
import { LocationService } from 'src/app/services/location/location.service';
import { COUNTRY_PHONE_CODES } from 'src/app/constants/phone-number-field.constant';
import moment from 'moment';

export interface RegistrationStateModel {
    fieldSections: RegistrationFieldSections[];
    registrationFormData:any;
    allConferencesFormData:any;
    roleList: any[];
    accountList: any[];
    registerPayloadData: any;
    phoneDetail: any;
    basicDetailFormData: any
}

@State<RegistrationStateModel>({
    name: 'registration',
    defaults: {
        fieldSections: [],
        registrationFormData: {},
        allConferencesFormData: {},
        roleList: [],
        accountList: [],
        registerPayloadData: {},
        phoneDetail: {},
        basicDetailFormData: null
    }
})
@Injectable()
export class RegistrationState {

    @Selector()
    static getState(state: RegistrationStateModel) {
        return state;
    }

    @Selector()
    static getRegisterPayload(state: RegistrationStateModel) {
        return state.registerPayloadData;
    }

    @Selector() 
    static fieldSections(state: RegistrationStateModel) {
        return state.fieldSections
    }

    @Selector()
    static registationFormData(state: RegistrationStateModel) {
        return state.registrationFormData
    }

    @Selector()
    static roleList(state: RegistrationStateModel) {
        return state.roleList
    }

    @Selector()
    static accountList(state: RegistrationStateModel) {
        return state.accountList
    }

    @Selector()
    static phoneDetail(state: RegistrationStateModel) {
        return state.phoneDetail
    }

    @Selector() 
    static basicDetailFormData(state: RegistrationStateModel) {
      return state.basicDetailFormData
    }

    constructor(private _register: RegistrationService, private _popup: PopupService, private _location: LocationService) { }

    @Action(RegistrationAction.GetRegisterData)
    async getRegisterData({ patchState, getState }: StateContext<RegistrationStateModel>, action: RegistrationAction.GetRegisterData) {
        const state = getState();
        const activeConferenceData = state.allConferencesFormData[action.data.conferenceId]
        if(!activeConferenceData) {
          let data: any = await this._register.getRegistrationFields(action.data);
          let response:any = {};
          response[action.data.conferenceId] = data
          patchState({
            fieldSections: data.fieldSections,
            registrationFormData:data,
            allConferencesFormData: {...state.allConferencesFormData, ...response}
          })
        } else {
            patchState({
                fieldSections: activeConferenceData.fieldSections,
                registrationFormData: activeConferenceData
            })
        }
    }

    @Action(RegistrationAction.GetRoleAction)
    async getRole({getState, patchState}: StateContext<RegistrationStateModel>) {
      let state = getState()
      if(!state.roleList.length) {
        let response:any[] = await this._register.getRole();
        patchState({
            roleList: response
        })
      }
    }

    @Action(RegistrationAction.GetAccountAction)
    async getAccount({getState, patchState}: StateContext<RegistrationStateModel>) {
      let state = getState()
      if(!state.accountList.length) {
        let response:any[] = await this._register.getAccount();
        patchState({
            accountList: response
        })
      }
    }

    @Action(RegistrationAction.SaveRegisterPayloadAction)
    saveRegisterAction({getState, patchState}: StateContext<RegistrationStateModel>, action: RegistrationAction.SaveRegisterPayloadAction) {
        let state = getState()
        patchState({
            registerPayloadData: {...state.registerPayloadData, ...action.data}
        })
    }

    @Action(RegistrationAction.DownloadPreferenceFile)
    async downloadPreference({getState}: StateContext<RegistrationStateModel>, action: RegistrationAction.DownloadPreferenceFile) {
      const state = getState()
      let data = await this._register.downloadFile(action.data)
      const dataType = data.type;
        const binaryData = [];
        binaryData.push(data);

        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.download = "Preferences.xlsx";
        document.body.appendChild(downloadLink);
        downloadLink.click();

        setTimeout(() => downloadLink.remove(), 1000);
      }
        
    

    @Action(RegistrationAction.UploadPreferenceFile)
    async uploadPreference({getState}: StateContext<RegistrationStateModel>, action: RegistrationAction.UploadPreferenceFile) {
      const state = getState()
      let res = await this._register.uploadFile(action.data)
      if(res) {
        this._popup.success(FILE_UPLOAD_SUCCESSFULLY)
      }
        
    }

    @Action(RegistrationAction.RegisterUserAction)
    async registerUser({getState, patchState}: StateContext<RegistrationStateModel>) {
        let state = getState();
        let Availibility
        if(state.registerPayloadData?.Availibility) {
          Availibility = state.registerPayloadData.Availibility.filter((item:any) => {
            return item.IsAvailable !== ''
          })
          Availibility = Availibility.map((item:any) => {
            return {...item, AvailibilityStartDate: moment(new Date(item.AvailibilityStartDate)).utcOffset(0,true).format(),
               AvailibilityEndDate: moment(new Date(item.AvailibilityEndDate)).utcOffset(0,true).format()}
          })
        }
        let data = await this._register.register({...state.registerPayloadData, Availibility})
        if(data) {
            let updatedRegisteredData = {...state.allConferencesFormData};
            console.log(updatedRegisteredData[state.registerPayloadData.ConferenceId])
            delete updatedRegisteredData[state.registerPayloadData.ConferenceId]

            patchState({
                allConferencesFormData: updatedRegisteredData,
                registerPayloadData: null
            })
            this._popup.success('Registered Successfully')
        }
    }

    @Action(RegistrationAction.SaveCurrentCountryCode)
    async saveCurrentCountryCode({getState, patchState}: StateContext<RegistrationStateModel>) {
      let state = getState();
       if(!state.phoneDetail?.countryCode) {
         let location = await this._location.getCurrentCountryCode()
         const phoneDetail = COUNTRY_PHONE_CODES.find(item => item.countryCode === location.country_code)
         patchState({
          phoneDetail
         })
       } 
    }

    @Action(RegistrationAction.SaveBasicDetailFormData)
    saveBasiDetailFormData({getState, patchState}: StateContext<RegistrationStateModel>, action: RegistrationAction.SaveBasicDetailFormData) {
      const state = getState();
      patchState({
        basicDetailFormData: action.basicDetail
      })
    }

}
