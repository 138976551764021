export namespace RegistrationAction {
    export class GetRegisterData {
        static readonly type ='[Registration] Get Register data';
        constructor(public readonly data:any) { }
    }

    export class GetRoleAction {
        static readonly type = '[Registration] Get Role';
    }

    export class GetAccountAction {
       static readonly type = '[Registration] get Account'
    }

    export class SaveRegisterPayloadAction {
        static readonly type = '[Registration] Save register payload';
        constructor(public readonly data:any) { }
    }

    export class RegisterUserAction {
        static readonly type = '[Registration] Register User';
    }

    export class DownloadPreferenceFile {
        static readonly type = '[Registration] Download preferences file';
        constructor(public readonly data: any) { }
    }

    export class UploadPreferenceFile {
        static readonly type = '[Registration] Upload preferences file';
        constructor(public readonly data: any) { }
    }

    export class SaveCurrentCountryCode {
        static readonly type = '[Registration] Save current country code';
    }

    export class SaveBasicDetailFormData {
        static readonly type = '[Registration] Save BasicDetail Form Data';
        constructor(public readonly basicDetail: any) { }
    }

}
