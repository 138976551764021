import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { API_URL } from 'src/app/constants/url';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable()
export class RegistrationService {

  constructor(private _http: HttpService, private _httpClient: HttpClient) { }

  getRegistrationFields(data:any) {
    return lastValueFrom(this._http.get(API_URL.registrationfields, data, false).pipe(map(response => {
      const data = response as unknown as any;
      return data; 
    })))
  }

  getRole() {
    return lastValueFrom(this._http.get(API_URL.getRole, {}, false))
  }

  getAccount() {
    return lastValueFrom(this._http.get(API_URL.getAccount, {}, false))
  }

 register(data:any) {
   return lastValueFrom(this._http.post(API_URL.saveRegistration, data, true, 'text'))
 }

 downloadFile(data:any) {
  return lastValueFrom(this._httpClient.post(API_URL.downloadFile, data, {responseType: 'blob'}))
 }

 uploadFile(data:any) {
  return lastValueFrom(this._http.post(API_URL.uploadFile, data, false, 'text'))
 }
}
